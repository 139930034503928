import { useSelector } from "react-redux";
import DataTableBase from "./DataTableBase";
import { useTranslation } from "react-i18next";
import React from "react";
import { useNavigate } from "react-router-dom";
import { store } from "../../store";

export default function EstablishmentsTable() {
  const establishments = useSelector((state) => state.establishments);
  const brands = useSelector((state) => state.brands);
  const userDB = useSelector((state) => state.userDB);

  const navigate = useNavigate();

  const { t } = useTranslation("common");

  const columns = [
    {
      name: "id",
      label: "ID",
      options: {
        display: false,
        filter: false,
        sort: true,
      },
    },
    {
      name: "name",
      label: t("common.name"),
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "stars",
      label: t("common.stars"),
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => {
          //use the value to show stars
          return (
            //value is int, show stars
            <div>
              {Array.from({ length: value }, (_, index) => (
                <span key={index}>&#9733;</span>
              ))}
            </div>
          );
        },
      },
    },
    {
      name: "brand_id",
      label: t("common.brand"),
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => {
          let brand = brands.find(
            (brand) => parseInt(brand.id) === parseInt(value)
          );

          return brand ? brand.name : "";
        },
      },
    },
  ];

  const [selectedEstablishments, setSelectedestablishments] = React.useState(
    []
  );
  const [selectedRows, setSelectedRows] = React.useState([]);

  const tableActions = [
    {
      name: t("common.delete_selected", {
        name: t("common.establishments"),
        count: selectedEstablishments.length,
      }),
      icon: "Delete",
      action: () => {
        store.dispatch({
          type: "deleteDialog/update",
          payload: {
            open: true,
            type: "establishments",
            records: selectedEstablishments,
          },
        });
      },
    },
  ];

  return (
    <DataTableBase
      columns={columns}
      data={establishments}
      title={t("common.establishments")}
      id="establishment-list"
      name="establishment-list"
      pageSize={10}
      selectedItems={selectedEstablishments}
      setSelectedItems={setSelectedestablishments}
      selectedRows={selectedRows}
      setSelectedRows={setSelectedRows}
      selectableRows={userDB ? (userDB.admin ? "multiple" : "none") : "none"}
      actions={tableActions}
      sortOrder="dev_eui"
      onRowClick={(rowData) => {
        navigate(`/establishments/${rowData[0]}`);
      }}
    />
  );
}
