import { withAuthenticationRequired } from "@auth0/auth0-react";
import { store } from "../store";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { TextField, Button, MenuItem, FormControl, Grid } from "@mui/material";
import fetchData from "../API/Fetch";

function Export() {
  const { t } = useTranslation("common");

  const [month, setMonth] = useState(
    new Date().toLocaleString("default", { month: "2-digit" })
  );
  const [year, setYear] = useState(new Date().getFullYear());

  const establishments = useSelector((state) => state.establishments);
  const [selectedEstablishment, setSelectedEstablishment] = useState(
    establishments ? (establishments[0] ? establishments[0].id : "") : ""
  );

  const downloadCsv = async () => {
    let data = await fetchData(
      `/establishments/report/${selectedEstablishment}/${month}${year}`
    );

    //csv name is establishmentname_year_month.csv
    let establishment = establishments.find(
      (establishment) =>
        parseInt(establishment.id) === parseInt(selectedEstablishment)
    );

    let filename = `${establishment.name}_${year}_${month}.csv`;

    //create csv from data (data is already in csv format)
    let csv = new Blob([data], { type: "text/csv" });

    //force the download of the new file
    let url = window.URL.createObjectURL(csv);
    let a = document.createElement("a");
    a.href = url;
    a.download = filename;
    a.click();
    window.URL.revokeObjectURL(url);
  };

  useEffect(() => {
    let title = t("common.exporter");

    store.dispatch({
      type: "pageTitle/update",
      payload: title,
    });

    store.dispatch({ type: "pageActions/update", payload: [] });
  }, [t]);

  return (
    <Grid item xs={12}>
      <FormControl
        fullWidth
        sx={{
          marginBottom: 2,
          marginTop: 2,
        }}
      >
        <TextField
          select
          label={t("common.establishment")}
          onChange={(event) => {
            setSelectedEstablishment(event.target.value);
          }}
          value={selectedEstablishment}
        >
          {establishments.map((option) => (
            <MenuItem key={option.id} value={option.id}>
              {option.name}
            </MenuItem>
          ))}
        </TextField>
      </FormControl>
      <FormControl
        fullWidth
        sx={{
          marginBottom: 2,
        }}
      >
        <TextField
          select
          label={t("common.month")}
          onChange={(event) => {
            setMonth(event.target.value);
          }}
          value={month}
          type="number"
        >
          <MenuItem value="01">{t("common.january")}</MenuItem>
          <MenuItem value="02">{t("common.february")}</MenuItem>
          <MenuItem value="03">{t("common.march")}</MenuItem>
          <MenuItem value="04">{t("common.april")}</MenuItem>
          <MenuItem value="05">{t("common.may")}</MenuItem>
          <MenuItem value="06">{t("common.june")}</MenuItem>
          <MenuItem value="07">{t("common.july")}</MenuItem>
          <MenuItem value="08">{t("common.august")}</MenuItem>
          <MenuItem value="09">{t("common.september")}</MenuItem>
          <MenuItem value="10">{t("common.october")}</MenuItem>
          <MenuItem value="11">{t("common.november")}</MenuItem>
          <MenuItem value="12">{t("common.december")}</MenuItem>
        </TextField>
      </FormControl>
      <FormControl
        fullWidth
        sx={{
          marginBottom: 2,
        }}
      >
        <TextField
          select
          label={t("common.year")}
          type="number"
          onChange={(event) => {
            setYear(event.target.value);
          }}
          value={year}
        >
          {
            // generate years from 2024 to current year
            Array.from(
              { length: new Date().getFullYear() - 2023 },
              (_, i) => i + 2024
            ).map((year) => (
              <MenuItem key={year} value={year}>
                {year}
              </MenuItem>
            ))
          }
        </TextField>
      </FormControl>
      <Button
        variant="contained"
        color="success"
        onClick={() => {
          downloadCsv();
        }}
      >
        {t("common.export")}
      </Button>
    </Grid>
  );
}

export default withAuthenticationRequired(Export);
