import { withAuthenticationRequired } from "@auth0/auth0-react";
import { store } from "../store";
import { useTranslation } from "react-i18next";
import { Grid } from "@mui/material";
import ResidencesTable from "../components/tables/Residences";
import { useEffect } from "react";
import { useSelector } from "react-redux";

function Residences(props) {
  const { t } = useTranslation("common");

  const userDB = useSelector((state) => state.userDB);

  useEffect(() => {
    store.dispatch({
      type: "pageTitle/update",
      payload: t("common.residences"),
    });

    let pageActions = [];

    if (userDB && userDB.admin) {
      pageActions = [
        {
          name: t("common.add_new", { name: "" }),
          icon: "Add",
          url: "/add/residence",
        },
      ];
    }

    store.dispatch({ type: "pageActions/update", payload: pageActions });
  }, [t, userDB]);

  return (
    <Grid item xs={12}>
      <ResidencesTable />
    </Grid>
  );
}

export default withAuthenticationRequired(Residences);
