import { withAuthenticationRequired } from "@auth0/auth0-react";
import { store } from "../store";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import ResidenceDashboard from "../components/ResidenceDashboard";
import ResidenceDashboardCards from "../components/ResidenceDashboardCards";

function Dashboard(props) {
  const { t } = useTranslation("common");

  const isMobile = window.innerWidth < 900;

  useEffect(() => {
    store.dispatch({
      type: "pageTitle/update",
      payload: t("common.dashboard"),
    });

    let pageActions = [
      {
        name: "SelectEstablishment",
      },
    ];

    store.dispatch({ type: "pageActions/update", payload: pageActions });
  }, [t]);

  return isMobile ? <ResidenceDashboardCards /> : <ResidenceDashboard />;
}

export default withAuthenticationRequired(Dashboard);
