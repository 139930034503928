import { Icon, Card, Grid, Typography, Box } from "@mui/material";
import { useNavigate } from "react-router-dom";

const deviceTypes = [
  { id: 1, name: "SenScreen" },
  { id: 2, name: "Milesight Ct101" },
  { id: 3, name: "Quandify Cubic Meter" },
  { id: 4, name: "Eastron Mid" },
  { id: 5, name: "RadioEvo" },
];

export default function DeviceCard(props) {
  const { device } = props;

  const navigate = useNavigate();

  if (!device) return null;

  return (
    <Grid item xs={12} md={6} lg={4} key={device.dev_eui}>
      <Card
        elevation={3}
        sx={{
          p: 2,
          mb: 1,
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          borderRadius: 2,
          cursor: "pointer",
          transition: "transform 0.3s ease, box-shadow 0.3s ease",
          "&:hover": {
            transform: "translateY(-2px)",
            boxShadow: 6,
          },
        }}
        onClick={() => {
          //navigate to the residence usage page
          navigate("/devices/" + device.dev_eui);
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Box>
              <Typography
                variant="subtitle1"
                component="p"
                sx={{
                  fontSize: "1em",
                  fontWeight: "bold",
                  lineHeight: "1.4em",
                }}
              >
                {device.dev_eui}
              </Typography>
              <Typography
                variant="caption"
                component="p"
                sx={{
                  fontSize: "0.8em",
                  lineHeight: "0.8em",
                  color: "text.secondary",
                }}
              >
                {deviceTypes.find((type) => type.id === device.device_type_id)
                  ? deviceTypes.find(
                      (type) => type.id === device.device_type_id
                    ).name
                  : "Unknown"}
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={3} sx={{ display: "flex" }}>
            <Icon sx={{ color: "info.main", mr: 1, fontSize: "2em" }}>
              av_timer
            </Icon>
            <Typography
              variant="caption"
              component="p"
              sx={{
                fontSize: "1em",
                lineHeight: "1em",
                //vertical align in the middle
                display: "flex",
                alignItems: "center",
              }}
            >
              {device.fcnt}
            </Typography>
          </Grid>
          <Grid item xs={5} sx={{ display: "flex" }}>
            <Icon sx={{ color: "info.main", mr: 1, fontSize: "2em" }}>
              calendar_month
            </Icon>
            <Typography
              variant="caption"
              component="p"
              sx={{
                fontSize: "0.8em",
                lineHeight: "0.8em",
                display: "flex",
                alignItems: "center",
              }}
            >
              {new Date(device.time_updated).toLocaleDateString(undefined, {
                year: "numeric",
                month: "short",
                day: "numeric",
                hour: "2-digit",
                minute: "2-digit",
              })}
            </Typography>
          </Grid>
        </Grid>
      </Card>
    </Grid>
  );
}
