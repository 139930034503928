import { FormControl, MenuItem, TextField } from "@mui/material";
import { useSelector } from "react-redux";
import { store } from "../store";
import { useTranslation } from "react-i18next";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

export default function SelectEstablishment() {
  const establishments = useSelector((state) => state.establishments);
  const selectedEstablishment = useSelector(
    (state) => state.selectedEstablishment
  );

  const { t } = useTranslation("common");

  const navigate = useNavigate();

  const handleChange = (event) => {
    store.dispatch({
      type: "selectedEstablishment/update",
      payload: event.target.value,
    });
    localStorage.setItem("selectedEstablishment", event.target.value);
  };

  return (
    <div style={{ display: "flex", gap: "16px", width: "100%" }}>
      <div style={{ flex: "5" }}>
        <FormControl fullWidth>
          <TextField
            select
            label={t("common.establishment")}
            onChange={handleChange}
            value={selectedEstablishment}
          >
            <MenuItem key="0" value="0">
              {t("common.all")}
            </MenuItem>
            {establishments.map((option) => (
              <MenuItem key={option.id} value={option.id}>
                {option.name}
              </MenuItem>
            ))}
          </TextField>
        </FormControl>
      </div>
      <div style={{ flex: "1" }}>
        <Button
          variant="contained"
          onClick={() => {
            navigate("/establishments/" + selectedEstablishment);
          }}
          fullWidth
          sx={{ height: "100%", backgroundColor: "#6bc769" }}
        >
          {t("common.edit")}
        </Button>
      </div>
    </div>
  );
}
