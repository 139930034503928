import * as React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Stack from "@mui/material/Stack";
import HomeRoundedIcon from "@mui/icons-material/HomeRounded";

import SettingsRoundedIcon from "@mui/icons-material/SettingsRounded";
import {
  CorporateFare,
  DevicesRounded,
  ImportExport,
  LocalOffer,
  MeetingRoom,
  Monitor,
  PostAdd,
  Redeem,
} from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

export default function MenuContent(props) {
  const navigate = useNavigate();

  const { t } = useTranslation("common");

  const adminMenuItems = [
    {
      text: t("common.dashboard"),
      icon: <HomeRoundedIcon />,
      url: "/",
    },
    {
      text: t("common.devices"),
      icon: <DevicesRounded />,
      url: "/devices",
    },
    {
      text: t("common.screens"),
      icon: <Monitor />,
      url: "/screens",
    },
    {
      text: t("common.packs"),
      icon: <Redeem />,
      url: "/packs",
    },
    { text: t("common.brands"), icon: <LocalOffer />, url: "/brands" },
    {
      text: t("common.establishments"),
      icon: <CorporateFare />,
      url: "/establishments",
    },
    {
      text: t("common.residences"),
      icon: <MeetingRoom />,
      url: "/residences",
    },
    {
      text: t("common.export"),
      icon: <ImportExport />,
      url: "/export",
    },
  ];

  const userMenuItems = [
    {
      text: t("common.dashboard"),
      icon: <HomeRoundedIcon />,
      url: "/",
    },
    {
      text: t("common.export"),
      icon: <ImportExport />,
      url: "/export",
    },
  ];

  const secondaryListItems = [
    {
      text: t("common.settings"),
      icon: <SettingsRoundedIcon />,
      url: "/settings",
    },
    {
      text: t("common.import"),
      icon: <PostAdd />,
      url: "/import",
    },
  ];

  const userDB = useSelector((state) => state.userDB);

  const isAdmin = userDB ? userDB.admin : false;

  return (
    <Stack sx={{ flexGrow: 1, p: 1, justifyContent: "space-between" }}>
      <List dense>
        {isAdmin
          ? adminMenuItems.map((item, index) => (
              <ListItem
                key={index}
                disablePadding
                sx={{ display: "block" }}
                onClick={() => navigate(item.url)}
              >
                <ListItemButton
                  selected={window.location.pathname === item.url}
                >
                  <ListItemIcon>{item.icon}</ListItemIcon>
                  <ListItemText primary={item.text} />
                </ListItemButton>
              </ListItem>
            ))
          : userMenuItems.map((item, index) => (
              <ListItem
                key={index}
                disablePadding
                sx={{ display: "block" }}
                onClick={() => navigate(item.url)}
              >
                <ListItemButton
                  selected={window.location.pathname === item.url}
                >
                  <ListItemIcon>{item.icon}</ListItemIcon>
                  <ListItemText primary={item.text} />
                </ListItemButton>
              </ListItem>
            ))}
      </List>

      <List dense>
        {secondaryListItems.map((item, index) =>
          index > 0 && !isAdmin ? null : (
            <ListItem
              key={index}
              disablePadding
              sx={{ display: "block" }}
              onClick={() => navigate(item.url)}
            >
              <ListItemButton selected={window.location.pathname === item.url}>
                <ListItemIcon>{item.icon}</ListItemIcon>
                <ListItemText primary={item.text} />
              </ListItemButton>
            </ListItem>
          )
        )}
      </List>
    </Stack>
  );
}
