import { Box, Typography } from "@mui/material";
import { CONFIG } from "../../config";
import { useSelector } from "react-redux";

export default function HeaderLogo(props) {
  const themeMode = useSelector((state) => state.themeMode);

  //box with logo left, title right and desc below in a smaller font
  return (
    <Box
      sx={{ display: "flex", alignItems: "center" }}
      onClick={() => {
        window.location.href = "/";
      }}
    >
      <Box>
        <img
          src={themeMode === "dark" ? "/logo.png" : "/logo_light.png"}
          alt="EcoludiK"
          style={{ width: 65 }}
        />
      </Box>
      <Box sx={{ ml: 2 }}>
        <img
          src={themeMode === "dark" ? "/title.png" : "/title_light.png"}
          alt="EcoludiK"
          width="120"
          height="auto"
        />
        <Typography variant="body2" color="textSecondary">
          {CONFIG.version}
        </Typography>
      </Box>
    </Box>
  );
}
