import { withAuthenticationRequired } from "@auth0/auth0-react";
import { store } from "../store";
import { useTranslation } from "react-i18next";
import { Chip, Grid, Stack, Button } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import UsageMetric from "../components/cards/UsageMetric";
import LastCheckin from "../components/cards/LastCheckin";
import DeviceGraph from "../components/cards/DeviceGraph";
import DatePickerDialog from "../components/dialogs/DatePicker";
import { Bolt, People, WaterDrop } from "@mui/icons-material";
import fetchAvgDeviceHistory from "../functions/fetchAvgDeviceHistory";
import moment from "moment-timezone";
import { CONFIG } from "../config";
import {
  CalendarViewDay,
  CalendarViewMonth,
  CalendarViewWeek,
  ChevronLeft,
  ChevronRight,
} from "@mui/icons-material";
import { useTheme } from "@emotion/react";

function ResidenceUsage(props) {
  const { t } = useTranslation("common");
  const { id } = useParams();
  const residences = useSelector((state) => state.residences);
  const establishments = useSelector((state) => state.establishments);
  const brands = useSelector((state) => state.brands);
  const devices = useSelector((state) => state.devices);
  const userDB = useSelector((state) => state.userDB);
  const packs = useSelector((state) => state.packs);
  const [startDate, setStartDate] = useState(
    moment().tz("Europe/Paris").startOf("day")
  );
  const [endDate, setEndDate] = useState(
    moment().tz("Europe/Paris").endOf("day")
  );
  const [yellow_water_limit, setYellowWaterLimit] = useState(0);
  const [yellow_elec_limit, setYellowElecLimit] = useState(0);
  const [red_water_limit, setRedWaterLimit] = useState(0);
  const [red_elec_limit, setRedElecLimit] = useState(0);

  const [isMobile, setIsMobile] = useState(window.innerWidth < 900);

  const theme = useTheme();

  const [water_color, setWaterColor] = useState(theme.palette.green.main);
  const [elec_color, setElecColor] = useState(theme.palette.green.main);

  const residence = residences.find(
    (residence) => parseInt(residence.id) === parseInt(id)
  );

  const establishment = establishments.find(
    (establishment) => establishment.id === residence?.establishment_id
  );

  const pack = packs.find((pack) => pack.id === residence?.pack_id);

  const brand = brands.find((brand) => brand.id === establishment?.brand_id);

  const waterMeter = pack?.water_deveui
    ? devices.find((device) => device.dev_eui === pack?.water_deveui)
    : null;

  const electricityMeter = pack?.elec_deveui
    ? devices.find((device) => device.dev_eui === pack?.elec_deveui)
    : null;

  const screenDevice = pack?.screen_deveui
    ? devices.find((device) => device.dev_eui === pack?.screen_deveui)
    : null;

  //yelow and red elec limits are based on per day and per person, we need to calculate the total days and total persons
  const totalDays = moment(endDate).diff(startDate, "days") + 1;

  let totalPersons = residence ? residence.current_occupation : 1;

  //check if totalPersons is 0, if so set it to 1 to avoid division by 0
  if (totalPersons === 0) {
    totalPersons = 1;
  }

  //add a window resize event listener to change the isMobile variable
  window.addEventListener("resize", () => {
    setIsMobile(window.innerWidth < 900);
  });

  useEffect(() => {
    if (!residence || !establishment || !brand) return;
    // brands, establishments and residences can have different limits for water and elec, use residence if not null, else use establishment, else use brand
    let yellow_water_limit =
      residence.yellow_water_limit_per_person ||
      establishment.yellow_water_limit_per_person ||
      brand.yellow_water_limit_per_person;

    let yellow_elec_limit =
      residence.yellow_elec_limit_per_person ||
      establishment.yellow_elec_limit_per_person ||
      brand.yellow_elec_limit_per_person;

    let red_water_limit =
      residence.red_water_limit_per_person ||
      establishment.red_water_limit_per_person ||
      brand.red_water_limit_per_person;

    let red_elec_limit =
      residence.red_elec_limit_per_person ||
      establishment.red_elec_limit_per_person ||
      brand.red_elec_limit_per_person;

    setYellowWaterLimit(yellow_water_limit);
    setYellowElecLimit(yellow_elec_limit);
    setRedWaterLimit(red_water_limit);
    setRedElecLimit(red_elec_limit);
  }, [residence, establishment, brand]);

  const [dateType, setDateType] = useState("day");

  const [waterMeterHistory, setWaterMeterHistory] = useState([]);
  const [electricityMeterHistory, setElectricityMeterHistory] = useState([]);
  const [waterMeterTotalForPeriod, setWaterMeterTotalForPeriod] = useState(0);
  const [electricityMeterTotalForPeriod, setElectricityMeterTotalForPeriod] =
    useState(0);
  const [grouped_by, setGroupedBy] = useState("hourly");
  const [showDatePicker, setShowDatePicker] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    store.dispatch({
      type: "pageTitle/update",
      payload: t("common.residence_usage", {
        name: residence ? residence.name : "",
      }),
    });

    store.dispatch({ type: "pageActions/update", payload: [] });
  }, [t, residence, startDate, endDate, dateType]);

  useEffect(() => {
    //if the date range is more than 1 day, make sure we are set to group_by daily

    //work out the difference between the start and end date
    const diff = moment(endDate).diff(startDate, "days");

    if (diff > 1) {
      setGroupedBy("daily");
    } else {
      setGroupedBy("hourly");
    }

    async function fetchUsage() {
      const avgWater = waterMeter
        ? fetchAvgDeviceHistory(
            waterMeter.dev_eui,
            "total_liters",
            startDate,
            endDate,
            grouped_by
          )
        : Promise.resolve([]);

      const avgElec = electricityMeter
        ? fetchAvgDeviceHistory(
            electricityMeter.dev_eui,
            "total_kwh",
            startDate,
            endDate,
            grouped_by
          )
        : Promise.resolve([]);

      const [waterData, elecData] = await Promise.all([avgWater, avgElec]);

      setWaterMeterHistory(waterData);
      setElectricityMeterHistory(elecData);

      //check there is rows to reduce
      if (waterData && waterData.length !== 0) {
        const totalWater = waterData.reduce(
          (total, value) => total + value.total_liters,
          0
        );
        //water round to 0 decimal places
        setWaterMeterTotalForPeriod(Math.round(totalWater * 100) / 100);
      } else {
        setWaterMeterTotalForPeriod(0);
      }

      if (elecData && elecData.length !== 0) {
        const totalElec = elecData.reduce(
          (total, value) => total + value.total_kwh,
          0
        );
        //elec round to 1 decimal place
        let total_elec = totalElec;
        //round to a single decimal place
        total_elec = Math.round(total_elec * 10) / 10;

        setElectricityMeterTotalForPeriod(total_elec);
      } else {
        setElectricityMeterTotalForPeriod(0);
      }
    }

    fetchUsage();

    //set an interval to update
    const interval = setInterval(() => {
      fetchUsage();
    }, CONFIG.defaultRefreshRate * 1000);

    return () => clearInterval(interval);
  }, [startDate, endDate, residence, electricityMeter, waterMeter, grouped_by]);

  function changePeriod(period) {
    let sd = moment(startDate);
    let ed = moment(endDate);

    if (period === "minus") {
      if (dateType === "day") {
        sd = sd.subtract(1, "days");
        ed = ed.subtract(1, "days");
      } else if (dateType === "week") {
        sd = sd.subtract(1, "weeks");
        ed = ed.subtract(1, "weeks");
        sd = sd.startOf("isoWeek");
        ed = ed.endOf("isoWeek");
      } else if (dateType === "month") {
        sd = sd.subtract(1, "months");
        ed = ed.subtract(1, "months");
        sd = sd.startOf("month");
        ed = ed.endOf("month");
      }
    }

    if (period === "plus") {
      if (dateType === "day") {
        sd = sd.add(1, "days");
        ed = ed.add(1, "days");
      } else if (dateType === "week") {
        sd = sd.add(1, "weeks");
        ed = ed.add(1, "weeks");
        sd = sd.startOf("isoWeek");
        ed = ed.endOf("isoWeek");
      } else if (dateType === "month") {
        sd = sd.add(1, "months");
        ed = ed.add(1, "months");
        sd = sd.startOf("month");
        ed = ed.endOf("month");
      }
    }

    if (period === "day") {
      sd = moment().tz("Europe/Paris").startOf("day");
      ed = moment().tz("Europe/Paris").endOf("day");
      setGroupedBy("hourly");
      setDateType("day");
    } else if (period === "week") {
      sd = moment().tz("Europe/Paris").startOf("week");
      ed = moment().tz("Europe/Paris").endOf("week");
      setGroupedBy("daily");
      setDateType("week");
    } else if (period === "month") {
      sd = moment().tz("Europe/Paris").startOf("month");
      ed = moment().tz("Europe/Paris").endOf("month");
      setGroupedBy("daily");
      setDateType("month");
    }

    setStartDate(sd);
    setEndDate(ed);
  }

  useEffect(() => {
    const yellowElecLimit = yellow_elec_limit * totalPersons * totalDays;
    const redElecLimit = red_elec_limit * totalPersons * totalDays;

    if (electricityMeterTotalForPeriod > redElecLimit) {
      setElecColor(theme.palette.red.main);
    }
    if (
      electricityMeterTotalForPeriod > yellowElecLimit &&
      electricityMeterTotalForPeriod < redElecLimit
    ) {
      setElecColor(theme.palette.yellow.main);
    }
    if (electricityMeterTotalForPeriod < yellowElecLimit) {
      setElecColor(theme.palette.green.main);
    }

    const yellowWaterLimit = yellow_water_limit * totalPersons * totalDays;
    const redWaterLimit = red_water_limit * totalPersons * totalDays;

    if (waterMeterTotalForPeriod > redWaterLimit) {
      setWaterColor(theme.palette.red.main);
    }
    if (
      waterMeterTotalForPeriod > yellowWaterLimit &&
      waterMeterTotalForPeriod <= redWaterLimit
    ) {
      setWaterColor(theme.palette.yellow.main);
    }
    if (waterMeterTotalForPeriod <= yellowWaterLimit) {
      setWaterColor(theme.palette.green.main);
    }
  }, [
    yellow_elec_limit,
    red_elec_limit,
    electricityMeterTotalForPeriod,
    yellow_water_limit,
    red_water_limit,
    waterMeterTotalForPeriod,
    residence,
    startDate,
    endDate,
    theme,

    totalPersons,
    totalDays,
  ]);

  return (
    <>
      {userDB && userDB.admin && residence && residence.pack_id && (
        <Grid
          item
          xs={6}
          sx={{ mb: 1, display: "flex", justifyContent: "flex-end" }}
        >
          <Chip
            label={t("common.pack") + ": " + residence.pack_id}
            sx={{ backgroundColor: theme.palette.green.main, color: "black" }}
            onClick={() => {
              navigate(`/packs/${residence.pack_id}`);
            }}
          />
        </Grid>
      )}
      <Grid item xs={12} md={12}>
        <Stack gap={1} direction={"row"}>
          <Button
            variant={dateType === "day" ? "contained" : "outlined"}
            onClick={() => changePeriod("day")}
            startIcon={!isMobile && <CalendarViewDay />}
            color="success"
            sx={{
              fontSize: isMobile ? "0.7rem" : "0.8rem",
            }}
            size="small"
          >
            {t("common.day")}
          </Button>
          <Button
            variant={dateType === "week" ? "contained" : "outlined"}
            onClick={() => changePeriod("week")}
            startIcon={!isMobile && <CalendarViewWeek />}
            color="success"
            sx={{
              fontSize: isMobile ? "0.7rem" : "0.8rem",
            }}
            size="small"
          >
            {t("common.week")}
          </Button>
          <Button
            variant={dateType === "month" ? "contained" : "outlined"}
            onClick={() => changePeriod("month")}
            startIcon={!isMobile && <CalendarViewMonth />}
            color="success"
            sx={{
              fontSize: isMobile ? "0.7rem" : "0.8rem",
            }}
          >
            {t("common.month")}
          </Button>
          <Button
            color="success"
            variant="outlined"
            onClick={() => {
              changePeriod("minus");
            }}
            sx={{
              fontSize: isMobile ? "0.7rem" : "0.8rem",
              pl: 0,
              pr: 0,
              minWidth: "20px",
            }}
          >
            <ChevronLeft />
          </Button>
          <Button
            startIcon={!isMobile && <CalendarViewDay />}
            color="success"
            variant="outlined"
            onClick={() => setShowDatePicker(!showDatePicker)}
            sx={{
              fontSize: isMobile ? "0.7rem" : "0.8rem",
            }}
            size="small"
          >
            {!isMobile
              ? moment(startDate).format("DD/MM/YYYY") +
                " - " +
                moment(endDate).format("DD/MM/YYYY")
              : moment(startDate).format("DD/MM") ===
                moment(endDate).format("DD/MM")
              ? moment(startDate).format("DD/MM/YY")
              : moment(startDate).format("DD/MM/YY") +
                " - " +
                moment(endDate).format("DD/MM/YY")}
          </Button>
          <Button
            color="success"
            variant="outlined"
            sx={{
              fontSize: isMobile ? "0.7rem" : "0.8rem",
              pl: 0,
              pr: 0,
              minWidth: "20px",
            }}
            onClick={() => {
              changePeriod("plus");
            }}
          >
            <ChevronRight />
          </Button>
        </Stack>
      </Grid>

      <Grid item xs={6} md={6}>
        <UsageMetric
          title={t("common.water_liter")}
          value={Math.round(waterMeterTotalForPeriod * 100) / 100}
          secondaryValue={
            Math.round(
              ((establishment ? establishment.cost_per_liter : 0) / 1000) *
                waterMeterTotalForPeriod *
                100
            ) /
              100 +
            "€"
          }
          unit="L"
          color={water_color}
          icon={
            <WaterDrop
              sx={{ fontSize: isMobile ? 50 : 80, color: water_color }}
            />
          }
        />
      </Grid>
      <Grid item xs={6} md={6}>
        <UsageMetric
          title={t("common.electricity_kwh")}
          value={Math.round(electricityMeterTotalForPeriod * 100) / 100}
          unit="kWh"
          color={elec_color}
          secondaryValue={
            Math.round(
              (establishment ? establishment.cost_per_kwh : 0) *
                electricityMeterTotalForPeriod *
                100
            ) /
              100 +
            "€"
          }
          icon={
            <Bolt sx={{ fontSize: isMobile ? 50 : 80, color: elec_color }} />
          }
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <DeviceGraph
          title={t("common.water_liter")}
          data={waterMeterHistory}
          value="total_liters"
          aspect={4}
          groupedBy={grouped_by}
          unit="L"
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <DeviceGraph
          title={t("common.electricity_kwh")}
          data={electricityMeterHistory}
          value="total_kwh"
          aspect={4}
          groupedBy={grouped_by}
          unit="kWh"
        />
      </Grid>

      <Grid item xs={12} md={3}>
        <UsageMetric
          title={t("common.current_occupancy")}
          value={residence ? residence.current_occupation : 0}
          unit={"of " + (residence ? residence.capacity : 0)}
          icon={<People sx={{ fontSize: 80 }} />}
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <LastCheckin
          title={t("common.last_checkin", {
            name: t("common.electricity_meter"),
          })}
          value={electricityMeter ? electricityMeter.time_updated : "N/A"}
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <LastCheckin
          title={t("common.last_checkin", { name: t("common.water_meter") })}
          value={waterMeter ? waterMeter.time_updated : "N/A"}
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <LastCheckin
          title={t("common.last_checkin", { name: t("common.screen") })}
          value={screenDevice ? screenDevice.time_updated : "N/A"}
        />
      </Grid>
      <DatePickerDialog
        open={showDatePicker}
        setOpen={setShowDatePicker}
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
        onClose={() => setShowDatePicker(false)}
        setGroupedBy={setGroupedBy}
      />
    </>
  );
}

export default withAuthenticationRequired(ResidenceUsage);
