import SideMenu from "./SideMenu";
import AppNavbar from "./AppNavbar";
import { Alert, IconButton, Snackbar } from "@mui/material";
import Header from "./Header";
import Box from "@mui/material/Box";
import { useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import { Close } from "@mui/icons-material";
import { handleSnackbarClose } from "../../functions/handleSnackbarClose";
import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Typography, Stack } from "@mui/material";
import PageActions from "./PageActions";
import DeleteDialog from "../dialogs/Delete";

export default function Layout(props) {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const pageTitle = useSelector((state) => state.pageTitle);
  const themeMode = useSelector((state) => state.themeMode);
  const snackbar = useSelector((state) => state.snackbar);

  const [isMobile, setIsMobile] = useState(window.innerWidth < 900);

  //add a window resize event listener to change the isMobile variable
  window.addEventListener("resize", () => {
    setIsMobile(window.innerWidth < 900);
  });

  const { t } = useTranslation("common");

  useEffect(() => {
    setSnackbarOpen(snackbar.open);
    setSnackbarMessage(snackbar.message);
  }, [snackbar]);

  return (
    <Box
      sx={{
        display: "flex",
        backgroundColor: themeMode === "dark" ? "#333" : "#f4f4f4",
        minHeight: "100vh",
      }}
    >
      <SideMenu />
      <AppNavbar />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          backgroundColor:
            themeMode === "dark" ? "rgba(0,0,0,0.9)" : "rgba(0,0,0,0.025)",
          pl: isMobile ? 1 : 3,
          pr: isMobile ? 1 : 3,
          pt: isMobile ? 11 : 1,
          pb: 2,
        }}
      >
        <Header pageTitle={pageTitle} themeMode={themeMode} t={t} />
        <Grid container spacing={1}>
          <Grid
            item
            xs={6}
            sx={{
              //vertical center
              display: "flex",
              alignItems: "center",
            }}
          >
            <Stack direction="row" spacing={1}>
              <Typography
                variant={isMobile ? "h6" : "h4"}
                component="h1"
                sx={{ color: themeMode === "dark" ? "white" : "black" }}
              >
                {pageTitle}
              </Typography>
            </Stack>
          </Grid>

          <PageActions />
          {props.children}
        </Grid>
      </Box>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        message={snackbarMessage}
        action={
          <React.Fragment>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={handleSnackbarClose}
            >
              <Close fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbar.severity ? snackbar.severity : "info"}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
      <DeleteDialog />
    </Box>
  );
}
