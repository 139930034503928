import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  Typography,
  Grid,
  Paper,
  Divider,
} from "@mui/material";
import DatePicker from "react-date-picker";
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";
import { useState } from "react";

export default function DatePickerDialog(props) {
  const {
    open,
    onClose,
    startDate,
    endDate,
    setStartDate,
    setEndDate,
    setGroupedBy,
  } = props;

  const [potentialEndDate, setPotentialEndDate] = useState(endDate);
  const [potentialStartDate, setPotentialStartDate] = useState(startDate);

  const handleApply = () => {
    //check the difference between the two dates, if its more than 25 hours, set grouped by to daily, otherwhise hourly
    const diff = Math.abs(potentialEndDate - potentialStartDate);
    const hours = Math.floor(diff / 36e5);
    if (hours > 25) {
      setGroupedBy("daily");
    } else {
      setGroupedBy("hourly");
    }
    setStartDate(potentialStartDate);
    setEndDate(potentialEndDate);
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="sm"
      PaperProps={{
        elevation: 3,
        sx: { borderRadius: 2 },
      }}
    >
      <DialogTitle
        sx={{
          py: 2,
        }}
      >
        Select Date Range
      </DialogTitle>
      <DialogContent sx={{ minHeight: "60vh", py: 4 }}>
        <Box sx={{ padding: 2 }}>
          <Grid container spacing={4} alignItems="center">
            <Grid item xs={12}>
              <Paper elevation={1} sx={{ p: 2, borderRadius: 2 }}>
                <Typography
                  variant="subtitle1"
                  fontWeight="medium"
                  gutterBottom
                >
                  Start Date
                </Typography>
                <DatePicker
                  value={potentialStartDate}
                  onChange={setPotentialStartDate}
                  clearIcon={null}
                  className="custom-datepicker"
                />
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <Paper elevation={1} sx={{ p: 2, borderRadius: 2 }}>
                <Typography
                  variant="subtitle1"
                  fontWeight="medium"
                  gutterBottom
                >
                  End Date
                </Typography>
                <DatePicker
                  value={potentialEndDate}
                  onChange={setPotentialEndDate}
                  clearIcon={null}
                  className="custom-datepicker"
                />
              </Paper>
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
      <Divider />
      <DialogActions sx={{ px: 3, py: 2 }}>
        <Button
          onClick={onClose}
          color="secondary"
          sx={{ fontWeight: "medium", px: 3 }}
        >
          Cancel
        </Button>
        <Button
          onClick={handleApply}
          color="primary"
          variant="contained"
          sx={{ fontWeight: "medium", px: 4 }}
        >
          Apply
        </Button>
      </DialogActions>
    </Dialog>
  );
}
