import Stack from "@mui/material/Stack";
import NavbarBreadcrumbs from "./NavbarBreadcrumbs";

import { IconButton, Tooltip } from "@mui/material";
import { Brightness4, Brightness7 } from "@mui/icons-material";
import handleThemeChange from "../../functions/handleThemeChange";

export default function Header(props) {
  const { t, themeMode } = props;

  return (
    <Stack
      direction="row"
      sx={{
        display: { xs: "none", md: "flex" },
        width: "100%",
        alignItems: { xs: "flex-start", md: "center" },
        justifyContent: "space-between",
        mb: 1,
      }}
      spacing={2}
    >
      <NavbarBreadcrumbs pageTitle={props.pageTitle} />

      <Tooltip
        title={
          themeMode && themeMode === "dark"
            ? t("common.switch_to_light_mode")
            : t("common.switch_to_dark_mode")
        }
        placement="bottom"
      >
        <IconButton
          aria-label="theme mode"
          sx={{ color: themeMode === "dark" ? "white" : "black" }}
          onClick={() => handleThemeChange()}
        >
          {themeMode && themeMode === "dark" ? (
            <Brightness7 />
          ) : (
            <Brightness4 />
          )}
        </IconButton>
      </Tooltip>
    </Stack>
  );
}
