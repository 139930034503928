import { withAuthenticationRequired } from "@auth0/auth0-react";
import { InputAdornment, MenuItem, TextField } from "@mui/material";
import { Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { store } from "../store";
import { useTranslation } from "react-i18next";
import { Controller, useForm } from "react-hook-form";
import { FormControl } from "@mui/material";
import { postData } from "../API/Post";
import updateSnackbarMessage from "../functions/updateSnackbarMessage";
import { Button } from "@mui/material";
import { Stack } from "@mui/material";
import { putData } from "../API/Put";
import fetchResidences from "../functions/fetchResidences";
import fetchData from "../API/Fetch";

function ResidenceUpdate() {
  const establishments = useSelector((state) => state.establishments);
  const packs = useSelector((state) => state.packs); // Add this line to get the packs from the store

  const userDB = useSelector((state) => state.userDB);

  const navigate = useNavigate();

  const [residence, setResidence] = useState(null);
  const [showLimits, setShowLimits] = useState(false);

  const params = useParams();
  const { t } = useTranslation("common");

  const { register, handleSubmit, control, reset } = useForm({
    reValidateMode: "onBlur",
    defaultValues: {
      name: "",
      current_occupation: 2,
      capacity: 4,
    },
  });

  const validationLabels = {
    name: {
      required: t("common.required", { name: t("common.name") }),
    },
    establishment: {
      required: t("common.required", { name: t("common.establishment") }),
    },
    current_occupation: {
      number: t("common.number_only"),
      required: t("common.required", { name: t("common.current_occupation") }),
    },

    capacity: {
      number: t("common.number_only"),
      required: t("common.required", { name: t("common.capacity") }),
    },

    yellow_water_limit_per_person: {
      number: t("common.number_only"),
    },
    yellow_elec_limit_per_person: {
      number: t("common.number_only"),
    },
    red_water_limit_per_person: {
      number: t("common.number_only"),
    },
    red_elec_limit_per_person: {
      number: t("common.number_only"),
    },
    screen_brightness: {
      number: t("common.number_only"),
      min: t("common.min") + " 0",
      max: t("common.max") + " 100",
    },
    uplink_rate_seconds: {
      number: t("common.number_only"),
    },
    screen_on_start: {
      number: t("common.number_only"),
      min: t("common.min") + " 0",
      max: t("common.max") + " 23",
    },
    screen_on_end: {
      number: t("common.number_only"),
      min: t("common.min") + " 0",
      max: t("common.max") + " 23",
    },
  };

  const onSubmit = async (data) => {
    let {
      name,
      establishment,
      current_occupation,
      capacity,
      yellow_water_limit_per_person,
      yellow_elec_limit_per_person,
      red_water_limit_per_person,
      red_elec_limit_per_person,
      screen_brightness,
      uplink_rate_seconds,
      screen_on_start,
      screen_on_end,
      pack_id,
    } = data;

    let jsonData = {
      name: name,
      establishment_id: establishment,
      current_occupation: current_occupation,
      capacity: capacity,
      yellow_water_limit_per_person: yellow_water_limit_per_person,
      yellow_elec_limit_per_person: yellow_elec_limit_per_person,
      red_water_limit_per_person: red_water_limit_per_person,
      red_elec_limit_per_person: red_elec_limit_per_person,
      screen_brightness: screen_brightness,
      uplink_rate_seconds:
        userDB && userDB.admin
          ? uplink_rate_seconds
          : establishment.uplink_rate_seconds,
      screen_on_start: screen_on_start,
      screen_on_end: screen_on_end,
      pack_id: userDB && userDB.admin ? pack_id : establishment.pack_id,
    };

    let response = null;

    if (residence) {
      response = await putData(`/residences/${residence.id}`, jsonData);
    } else {
      response = await postData(`/residences/`, jsonData);
    }

    if (response && response.status === 200) {
      updateSnackbarMessage(
        residence === null
          ? t("common.added_success", { name: t("common.residence") }) +
              " Redirecting to Residences..."
          : t("common.updated_success", { name: t("common.residence") }) +
              " Redirecting to Residences..."
      );

      fetchResidences();
      navigate("/residences/");
    } else {
      updateSnackbarMessage(
        residence === null
          ? t("common.add_error", { name: t("common.residence") })
          : t("common.update_error", { name: t("common.residence") })
      );
    }
  };

  useEffect(() => {
    store.dispatch({
      type: "pageTitle/update",
      payload: t("common.add_new", { name: t("common.residence") }),
    });

    store.dispatch({
      type: "pageActions/update",
      payload: [],
    });

    if (!params.id) {
      return;
    } else {
      store.dispatch({
        type: "pageTitle/update",
        payload: t("common.update") + " " + t("common.residence"),
      });
    }

    fetchData(`/residences/${params.id}`).then((data) => {
      setResidence(data);
    });
  }, [params, t]);

  useEffect(() => {
    if (residence) {
      reset({
        name: residence.name,
        current_occupation: residence.current_occupation,
        capacity: residence.capacity,
        establishment: residence.establishment_id,
        yellow_water_limit_per_person: residence.yellow_water_limit_per_person,
        yellow_elec_limit_per_person: residence.yellow_elec_limit_per_person,
        red_water_limit_per_person: residence.red_water_limit_per_person,
        red_elec_limit_per_person: residence.red_elec_limit_per_person,
        screen_brightness: residence.screen_brightness,
        uplink_rate_seconds: residence.uplink_rate_seconds,
        screen_on_start: residence.screen_on_start,
        screen_on_end: residence.screen_on_end,
        pack_id: residence.pack_id,
      });
    }
  }, [residence, reset]);

  return (
    <Grid item xs={12}>
      <Grid container spacing={2} direction={"row"}>
        <Grid item xs={3}>
          <Controller
            name="name"
            control={control}
            rules={{
              required: true,
            }}
            render={({ field, fieldState: { error } }) => (
              <FormControl
                fullWidth
                variant="outlined"
                sx={{
                  mt: 1,
                }}
              >
                <TextField
                  id="name"
                  label={t("common.name")}
                  fullWidth
                  {...field}
                  error={error !== undefined}
                  helperText={error ? validationLabels.name[error.type] : ""}
                  variant="outlined"
                  sx={{
                    mt: 1,
                  }}
                  InputLabelProps={{ shrink: true }}
                  {...register("name")}
                />
              </FormControl>
            )}
          />
        </Grid>

        <Grid item xs={3}>
          <Controller
            name="establishment"
            defaultValue={1}
            control={control}
            rules={{
              required: true,
            }}
            render={({ field, fieldState: { error } }) => (
              <FormControl
                fullWidth
                variant="outlined"
                sx={{
                  mt: 2,
                }}
              >
                <TextField
                  id="establishment"
                  label={t("common.establishment")}
                  fullWidth
                  {...field}
                  error={error !== undefined}
                  helperText={
                    error ? validationLabels.establishment[error.type] : ""
                  }
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                  select
                  {...register("establishment")}
                >
                  {establishments.map((establishment) => (
                    <MenuItem key={establishment.id} value={establishment.id}>
                      {establishment.name}
                    </MenuItem>
                  ))}
                </TextField>
              </FormControl>
            )}
          />
        </Grid>

        <Grid item xs={3}>
          <Controller
            name="current_occupation"
            control={control}
            rules={{
              required: true,
              number: true,
            }}
            render={({ field, fieldState: { error } }) => (
              <FormControl
                fullWidth
                variant="outlined"
                sx={{
                  mt: 1,
                }}
              >
                <TextField
                  id="current_occupation"
                  label={t("common.current_occupation")}
                  fullWidth
                  type={"number"}
                  {...field}
                  error={error !== undefined}
                  helperText={
                    error ? validationLabels.current_occupation[error.type] : ""
                  }
                  variant="outlined"
                  sx={{
                    mt: 1,
                  }}
                  {...register("current_occupation")}
                  InputLabelProps={{ shrink: true }}
                />
              </FormControl>
            )}
          />
        </Grid>

        <Grid item xs={3}>
          <Controller
            name="capacity"
            control={control}
            rules={{
              required: true,
              number: true,
            }}
            render={({ field, fieldState: { error } }) => (
              <FormControl
                fullWidth
                variant="outlined"
                sx={{
                  mt: 1,
                }}
              >
                <TextField
                  id="capacity"
                  label={t("common.capacity")}
                  fullWidth
                  type={"number"}
                  {...field}
                  error={error !== undefined}
                  helperText={
                    error ? validationLabels.capacity[error.type] : ""
                  }
                  variant="outlined"
                  sx={{
                    mt: 1,
                  }}
                  {...register("capacity")}
                  InputLabelProps={{ shrink: true }}
                />
              </FormControl>
            )}
          />
        </Grid>

        {userDB && userDB.admin ? (
          <Grid item xs={3}>
            <Controller
              name="pack_id"
              control={control}
              defaultValue={1}
              render={({ field, fieldState: { error } }) => (
                <FormControl
                  fullWidth
                  variant="outlined"
                  sx={{
                    mt: 1,
                  }}
                >
                  <TextField
                    id="pack_id"
                    label={t("common.pack")}
                    fullWidth
                    {...field}
                    error={error !== undefined}
                    helperText={error ? error.message : ""}
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                    select
                    {...register("pack_id")}
                  >
                    {packs.map((pack) => (
                      <MenuItem key={pack.id} value={pack.id}>
                        {pack.id}
                      </MenuItem>
                    ))}
                  </TextField>
                </FormControl>
              )}
            />
          </Grid>
        ) : null}

        {showLimits && (
          <>
            <Grid item xs={3}>
              <Controller
                name="yellow_water_limit_per_person"
                control={control}
                rules={{
                  number: true,
                }}
                render={({ field, fieldState: { error } }) => (
                  <FormControl
                    fullWidth
                    variant="outlined"
                    sx={{
                      mt: 1,
                    }}
                  >
                    <TextField
                      id="yellow_water_limit_per_person"
                      label={t("common.yellow_water_limit_per_person")}
                      type={"number"}
                      InputLabelProps={{ shrink: true }}
                      slotProps={{
                        input: {
                          endAdornment: (
                            <InputAdornment position="end">
                              Liters
                            </InputAdornment>
                          ),
                        },
                      }}
                      fullWidth
                      {...field}
                      error={error !== undefined}
                      helperText={
                        error
                          ? validationLabels.yellow_water_limit_per_person[
                              error.type
                            ]
                          : ""
                      }
                      variant="outlined"
                      sx={{
                        mt: 1,
                      }}
                      {...register("yellow_water_limit_per_person")}
                    />
                  </FormControl>
                )}
              />
            </Grid>
            <Grid item xs={3}>
              <Controller
                name="yellow_elec_limit_per_person"
                control={control}
                rules={{
                  number: true,
                }}
                render={({ field, fieldState: { error } }) => (
                  <FormControl
                    fullWidth
                    variant="outlined"
                    sx={{
                      mt: 1,
                    }}
                  >
                    <TextField
                      id="yellow_elec_limit_per_person"
                      label={t("common.yellow_elec_limit_per_person")}
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      slotProps={{
                        input: {
                          endAdornment: (
                            <InputAdornment position="end">kWh</InputAdornment>
                          ),
                        },
                      }}
                      {...field}
                      type={"number"}
                      error={error !== undefined}
                      helperText={
                        error
                          ? validationLabels.yellow_elec_limit_per_person[
                              error.type
                            ]
                          : ""
                      }
                      variant="outlined"
                      sx={{
                        mt: 1,
                      }}
                      {...register("yellow_elec_limit_per_person")}
                    />
                  </FormControl>
                )}
              />
            </Grid>
            <Grid item xs={3}>
              <Controller
                name="red_water_limit_per_person"
                control={control}
                rules={{
                  number: true,
                }}
                render={({ field, fieldState: { error } }) => (
                  <FormControl
                    fullWidth
                    variant="outlined"
                    sx={{
                      mt: 1,
                    }}
                  >
                    <TextField
                      id="red_water_limit_per_person"
                      label={t("common.red_water_limit_per_person")}
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      slotProps={{
                        input: {
                          endAdornment: (
                            <InputAdornment position="end">
                              Liters
                            </InputAdornment>
                          ),
                        },
                      }}
                      type={"number"}
                      {...field}
                      error={error !== undefined}
                      helperText={
                        error
                          ? validationLabels.red_water_limit_per_person[
                              error.type
                            ]
                          : ""
                      }
                      variant="outlined"
                      sx={{
                        mt: 1,
                      }}
                      {...register("red_water_limit_per_person")}
                    />
                  </FormControl>
                )}
              />
            </Grid>
            <Grid item xs={3}>
              <Controller
                name="red_elec_limit_per_person"
                control={control}
                //rules, number only
                rules={{
                  number: true,
                }}
                render={({ field, fieldState: { error } }) => (
                  <FormControl
                    fullWidth
                    variant="outlined"
                    sx={{
                      mt: 1,
                    }}
                  >
                    <TextField
                      id="red_elec_limit_per_person"
                      label={t("common.red_elec_limit_per_person")}
                      fullWidth
                      type="number"
                      InputLabelProps={{ shrink: true }}
                      slotProps={{
                        input: {
                          endAdornment: (
                            <InputAdornment position="end">kWh</InputAdornment>
                          ),
                        },
                      }}
                      {...field}
                      error={error !== undefined}
                      helperText={
                        error
                          ? validationLabels.red_elec_limit_per_person[
                              error.type
                            ]
                          : ""
                      }
                      variant="outlined"
                      sx={{
                        mt: 1,
                      }}
                      {...register("red_elec_limit_per_person")}
                    />
                  </FormControl>
                )}
              />
            </Grid>
            <Grid item xs={3}>
              <Controller
                name="screen_brightness"
                control={control}
                //rules number only, 0 - 100
                rules={{
                  number: true,
                  min: 0,
                  max: 100,
                }}
                render={({ field, fieldState: { error } }) => (
                  <FormControl
                    fullWidth
                    variant="outlined"
                    sx={{
                      mt: 1,
                    }}
                  >
                    <TextField
                      id="screen_brightness"
                      label={t("common.screen_brightness")}
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      slotProps={{
                        input: {
                          endAdornment: (
                            <InputAdornment position="end">%</InputAdornment>
                          ),
                        },
                      }}
                      {...field}
                      type={"number"}
                      error={error !== undefined}
                      helperText={
                        error
                          ? validationLabels.screen_brightness[error.type]
                          : ""
                      }
                      variant="outlined"
                      sx={{
                        mt: 1,
                      }}
                      {...register("screen_brightness")}
                    />
                  </FormControl>
                )}
              />
            </Grid>
            {userDB && userDB.admin ? (
              <Grid item xs={3}>
                <Controller
                  name="uplink_rate_seconds"
                  control={control}
                  rules={{
                    number: true,
                  }}
                  render={({ field, fieldState: { error } }) => (
                    <FormControl
                      fullWidth
                      variant="outlined"
                      sx={{
                        mt: 1,
                      }}
                    >
                      <TextField
                        id="uplink_rate_seconds"
                        label={t("common.uplink_rate_seconds")}
                        fullWidth
                        type={"number"}
                        InputLabelProps={{ shrink: true }}
                        slotProps={{
                          input: {
                            endAdornment: (
                              <InputAdornment position="end">
                                {t("common.seconds")}
                              </InputAdornment>
                            ),
                          },
                        }}
                        {...field}
                        error={error !== undefined}
                        helperText={
                          error
                            ? validationLabels.uplink_rate_seconds[error.type]
                            : ""
                        }
                        variant="outlined"
                        sx={{
                          mt: 1,
                        }}
                        {...register("uplink_rate_seconds")}
                      />
                    </FormControl>
                  )}
                />
              </Grid>
            ) : null}
            <Grid item xs={3}>
              <Controller
                name="screen_on_start"
                control={control}
                rules={{
                  number: true,
                  min: 0,
                  max: 23,
                }}
                render={({ field, fieldState: { error } }) => (
                  <FormControl
                    fullWidth
                    variant="outlined"
                    sx={{
                      mt: 1,
                    }}
                  >
                    <TextField
                      id="screen_on_start"
                      label={t("common.screen_on_start")}
                      type={"number"}
                      fullWidth
                      {...field}
                      InputLabelProps={{ shrink: true }}
                      error={error !== undefined}
                      helperText={
                        error
                          ? validationLabels.screen_on_start[error.type]
                          : ""
                      }
                      variant="outlined"
                      sx={{
                        mt: 1,
                      }}
                      {...register("screen_on_start")}
                    />
                  </FormControl>
                )}
              />
            </Grid>
            <Grid item xs={3}>
              <Controller
                name="screen_on_end"
                control={control}
                rules={{
                  number: true,
                  min: 0,
                  max: 23,
                }}
                render={({ field, fieldState: { error } }) => (
                  <FormControl
                    fullWidth
                    variant="outlined"
                    sx={{
                      mt: 1,
                    }}
                  >
                    <TextField
                      id="screen_on_end"
                      label={t("common.screen_on_end")}
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      type={"number"}
                      {...field}
                      error={error !== undefined}
                      helperText={
                        error ? validationLabels.screen_on_end[error.type] : ""
                      }
                      variant="outlined"
                      sx={{
                        mt: 1,
                      }}
                      {...register("screen_on_end")}
                    />
                  </FormControl>
                )}
              />
            </Grid>
          </>
        )}

        <Grid item xs={12}>
          <Stack
            spacing={2}
            direction="row"
            sx={{
              mt: 2,
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button
              variant="outlined"
              onClick={() => setShowLimits(!showLimits)}
            >
              {showLimits ? t("common.hide_limits") : t("common.show_limits")}
            </Button>
            <Button
              variant="contained"
              color="success"
              onClick={handleSubmit(onSubmit)}
            >
              {residence === null ? t("common.add") : t("common.update")}
            </Button>
          </Stack>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default withAuthenticationRequired(ResidenceUpdate);
