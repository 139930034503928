import { useSelector } from "react-redux";
import DataTableBase from "./DataTableBase";
import { useTranslation } from "react-i18next";
import React from "react";
import { useNavigate } from "react-router-dom";
import { store } from "../../store";

export default function BrandsTable() {
  const brands = useSelector((state) => state.brands);
  const users = useSelector((state) => state.users);
  const [selectedBrands, setSelectedbrands] = React.useState([]);
  const [selectedRows, setSelectedRows] = React.useState([]);

  const userDB = useSelector((state) => state.userDB);

  const navigate = useNavigate();

  const { t } = useTranslation("common");

  const columns = [
    {
      name: "id",
      label: "ID",
      options: {
        display: false,
        filter: false,
        sort: true,
      },
    },
    {
      name: "name",
      label: t("common.name"),
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "owner_id",
      label: t("common.owner"),
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => {
          const user = users.find((user) => user.id === value);
          return user ? user.email : "";
        },
      },
    },
  ];

  const tableActions = [
    {
      name: t("common.delete_selected", {
        name: t("common.brands"),
        count: selectedBrands.length,
      }),
      icon: "Delete",
      action: () => {
        store.dispatch({
          type: "deleteDialog/update",
          payload: {
            open: true,
            type: "brands",
            records: selectedBrands,
          },
        });
      },
    },
  ];

  if (!brands) {
    return <div>Loading...</div>;
  }

  return (
    <DataTableBase
      columns={columns}
      data={brands}
      title={t("common.brands")}
      id="brand-list"
      name="brand-list"
      pageSize={10}
      selectedItems={selectedBrands}
      setSelectedItems={setSelectedbrands}
      selectedRows={selectedRows}
      setSelectedRows={setSelectedRows}
      selectableRows={userDB && userDB.admin ? "multiple" : "none"}
      actions={tableActions}
      sortOrder="dev_eui"
      onRowClick={(rowData) => {
        navigate(`/brands/${rowData[0]}`);
      }}
    />
  );
}
