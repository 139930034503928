import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Typography } from "@mui/material";

import DashboardCard from "./DashboardCard";

export default function ResidenceDashboardCards() {
  const selectedEstablishment = useSelector(
    (state) => state.selectedEstablishment
  );

  const { t } = useTranslation("common");

  const residences = useSelector((state) => state.residences);

  const selectedResidences =
    selectedEstablishment !== "0"
      ? residences.filter(
          (residence) =>
            parseInt(residence.establishment_id) ===
            parseInt(selectedEstablishment)
        )
      : residences;

  return (
    <>
      {selectedResidences.length > 0 ? (
        selectedResidences.map((residence) => (
          <DashboardCard key={residence.id} residence={residence} />
        ))
      ) : (
        <Typography variant="h6">{t("common.no_residences")}</Typography>
      )}
    </>
  );
}
