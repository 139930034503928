//dialog to allow edit of occupation, capacity and name
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  Grid,
  Divider,
  TextField,
  CircularProgress,
} from "@mui/material";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { putData } from "../../API/Put";
import updateSnackbarMessage from "../../functions/updateSnackbarMessage";
import fetchResidences from "../../functions/fetchResidences";

export default function ResidenceUserEdit({ open, onClose, residenceId }) {
  const { t } = useTranslation("common");
  const residence = useSelector((state) =>
    state.residences.find((r) => parseInt(r.id) === parseInt(residenceId))
  );

  const [formData, setFormData] = useState({
    name: "",
    capacity: "",
    currentOccupation: "",
  });
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (residence) {
      setFormData({
        name: residence.name || "",
        capacity: residence.capacity || "",
        currentOccupation: residence.current_occupation || "",
      });
    }
  }, [residence]);

  const handleChange = (field) => (event) => {
    const value = event.target.value;
    setFormData((prev) => ({ ...prev, [field]: value }));
    // Clear error when field is modified
    if (errors[field]) {
      setErrors((prev) => ({ ...prev, [field]: "" }));
    }
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.name.trim()) {
      newErrors.name = t("common.required", { name: t("common.name") });
    }
    if (!formData.capacity || formData.capacity < 1) {
      newErrors.capacity = t("common.number_only");
    }
    if (!formData.currentOccupation || formData.currentOccupation < 0) {
      newErrors.currentOccupation = t("common.number_only");
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleApply = async () => {
    if (!validateForm()) return;

    setIsLoading(true);
    try {
      const residenceUpdate = {
        name: formData.name,
        capacity: Math.max(
          parseInt(formData.capacity),
          parseInt(formData.currentOccupation)
        ),
        current_occupation: parseInt(formData.currentOccupation),
        establishment_id: residence.establishment_id,
        yellow_water_limit_per_person: residence.yellow_water_limit_per_person,
        yellow_elec_limit_per_person: residence.yellow_elec_limit_per_person,
        red_water_limit_per_person: residence.red_water_limit_per_person,
        red_elec_limit_per_person: residence.red_elec_limit_per_person,
        screen_brightness: residence.screen_brightness,
        uplink_interval: residence.uplink_interval,
        uplink_rate_seconds: residence.uplink_rate_seconds,
        screen_on_start: residence.screen_on_start,
        screen_on_end: residence.screen_on_end,
        pack_id: residence.pack_id,
      };

      const result = await putData(
        `/residences/${residenceId}`,
        residenceUpdate
      );

      updateSnackbarMessage(
        t(
          result.status === 200
            ? "common.residence_update_success"
            : "common.residence_update_error"
        )
      );

      fetchResidences();

      onClose();
    } catch (error) {
      updateSnackbarMessage(t("common.residence_update_error"));
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="sm"
      PaperProps={{ elevation: 3, sx: { borderRadius: 2 } }}
    >
      <DialogTitle sx={{ py: 2 }}>
        {t("common.updateResidenceTitle")}
      </DialogTitle>
      <DialogContent sx={{ py: 4 }}>
        <Box sx={{ padding: 2 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                label={t("common.name")}
                variant="outlined"
                fullWidth
                value={formData.name}
                onChange={handleChange("name")}
                error={!!errors.name}
                helperText={errors.name}
                disabled={isLoading}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label={t("common.current_occupation")}
                variant="outlined"
                type="number"
                fullWidth
                value={formData.currentOccupation}
                onChange={handleChange("currentOccupation")}
                error={!!errors.currentOccupation}
                helperText={errors.currentOccupation}
                disabled={isLoading}
                inputProps={{ min: 0 }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label={t("common.capacity")}
                variant="outlined"
                type="number"
                fullWidth
                value={formData.capacity}
                onChange={handleChange("capacity")}
                error={!!errors.capacity}
                helperText={errors.capacity}
                disabled={isLoading}
                inputProps={{ min: 1 }}
              />
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
      <Divider />
      <DialogActions sx={{ px: 3, py: 2 }}>
        <Button
          onClick={onClose}
          color="secondary"
          sx={{ fontWeight: "medium", px: 3 }}
          disabled={isLoading}
        >
          {t("common.cancel")}
        </Button>
        <Button
          onClick={handleApply}
          color="primary"
          variant="contained"
          sx={{ fontWeight: "medium", px: 4 }}
          disabled={isLoading}
        >
          {isLoading ? (
            <CircularProgress size={24} color="inherit" />
          ) : (
            t("common.update")
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
