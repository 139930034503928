import { withAuthenticationRequired } from "@auth0/auth0-react";
import { store } from "../store";
import { useTranslation } from "react-i18next";
import { Grid } from "@mui/material";
import BrandsTable from "../components/tables/Brands";
import { useEffect } from "react";
import { useSelector } from "react-redux";

function Brands(props) {
  const { t } = useTranslation("common");

  const userDB = useSelector((state) => state.userDB);

  useEffect(() => {
    store.dispatch({ type: "pageTitle/update", payload: t("common.brands") });
    let pageActions = [];

    if (userDB && userDB.admin) {
      pageActions = [
        {
          name: t("common.add_new", { name: t("common.brand") }),
          icon: "Add",
          url: "/add/brand",
        },
      ];
    }

    store.dispatch({ type: "pageActions/update", payload: pageActions });
  }, [t, userDB]);

  return (
    <Grid item xs={12}>
      <BrandsTable />
    </Grid>
  );
}

export default withAuthenticationRequired(Brands);
