import DataTableBase from "./DataTableBase";
import { useTranslation } from "react-i18next";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import fetchData from "../../API/Fetch";

export default function DeviceHistoryTable(props) {
  const navigate = useNavigate();

  const dev_eui = props.dev_eui;

  const { t } = useTranslation("common");

  const [deviceHistory, setDeviceHistory] = React.useState();
  const [columns, setColumns] = React.useState();

  useEffect(() => {
    if (!dev_eui) return;

    const fetchHistory = async () => {
      const dhFetch = await fetchData("/deviceHistory/" + dev_eui + "/last");
      setDeviceHistory(dhFetch);

      //get the keys of the first item in the array and set them as columns
      if (dhFetch && dhFetch.length > 0) {
        setColumns(Object.keys(dhFetch[0]));
      }
    };

    fetchHistory();
  }, [dev_eui]);

  return (
    <DataTableBase
      columns={columns}
      data={deviceHistory ?? []}
      title={t("common.deviceHistory")}
      id="devicehistory-list"
      name="devicehistory-list"
      pageSize={10}
      sortOrder="time_created"
      onRowClick={(rowData) => {
        navigate(`/establishments/${rowData[0]}`);
      }}
    />
  );
}
