import { withAuthenticationRequired } from "@auth0/auth0-react";
import { store } from "../store";
import { useTranslation } from "react-i18next";
import { Grid, TextField, FormControl, MenuItem } from "@mui/material";
import { useEffect, useState } from "react";
import handleThemeChange from "../functions/handleThemeChange";
import { CONFIG } from "../config";
import i18next from "i18next";

function Settings() {
  const { t } = useTranslation("common");
  const [themeMode, setThemeMode] = useState(
    localStorage.getItem("theme") || CONFIG.defaultTheme
  );
  const [language, setLanguage] = useState(
    localStorage.getItem("language") || CONFIG.defaultLanguage
  );
  const [refreshRate, setRefreshRate] = useState(
    localStorage.getItem("refreshRate") || CONFIG.defaultRefreshRate
  );

  const updateRefreshRate = (rate) => {
    localStorage.setItem("refreshRate", rate);
    setRefreshRate(rate);
  };

  const changeLanguage = (lng) => {
    localStorage.setItem("language", lng);
    i18next.changeLanguage(lng);
    setLanguage(lng);
  };

  useEffect(() => {
    store.dispatch({ type: "pageTitle/update", payload: t("common.settings") });
    store.dispatch({ type: "pageActions/update", payload: [] });
  }, [t]);

  const renderTextField = (label, value, onChange, options) => (
    <Grid item xs={4}>
      <FormControl fullWidth>
        <TextField
          label={t(label)}
          variant="outlined"
          select
          value={value}
          onChange={(e) => onChange(e.target.value)}
        >
          {options}
        </TextField>
      </FormControl>
    </Grid>
  );

  const timeOptions = [
    { value: 15, label: "15 seconds" },
    { value: 30, label: "30 seconds" },
    { value: 60, label: "1 minute" },
    { value: 120, label: "2 minutes" },
    { value: 300, label: "5 minutes" },
    { value: 600, label: "10 minutes" },
    { value: 900, label: "15 minutes" },
    { value: 1800, label: "30 minutes" },
    { value: 3600, label: "1 hour" },
  ];

  return (
    <>
      <Grid item xs={12} />
      {renderTextField("common.language", language, changeLanguage, [
        <MenuItem key="en" value="en">
          {t("common.english")}
        </MenuItem>,
        <MenuItem key="fr" value="fr">
          {t("common.french")}
        </MenuItem>,
      ])}
      {renderTextField(
        "common.theme",
        themeMode,
        (value) => {
          setThemeMode(value);
          handleThemeChange(value);
        },
        [
          <MenuItem key="light" value="light">
            {t("common.light")}
          </MenuItem>,
          <MenuItem key="dark" value="dark">
            {t("common.dark")}
          </MenuItem>,
        ]
      )}
      {renderTextField(
        "common.refreshRate",
        refreshRate,
        updateRefreshRate,
        timeOptions.map(({ value, label }) => (
          <MenuItem key={value} value={value}>
            {value >= 60
              ? t(`common.${label}`)
              : `${value} ${t("common.seconds")}`}
          </MenuItem>
        ))
      )}
    </>
  );
}

export default withAuthenticationRequired(Settings);
