import { withAuthenticationRequired } from "@auth0/auth0-react";
import { InputAdornment, MenuItem, TextField } from "@mui/material";
import { Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { store } from "../store";
import { useTranslation } from "react-i18next";
import { Controller, useForm } from "react-hook-form";
import { FormControl } from "@mui/material";
import { postData } from "../API/Post";
import updateSnackbarMessage from "../functions/updateSnackbarMessage";
import { Button } from "@mui/material";
import { Stack } from "@mui/material";
import { putData } from "../API/Put";
import fetchEstablishments from "../functions/fetchEstablishments";
import fetchData from "../API/Fetch";

function EstablishmentUpdate() {
  const brands = useSelector((state) => state.brands);
  const params = useParams();
  const { t } = useTranslation("common");
  const navigate = useNavigate();

  const userDB = useSelector((state) => state.userDB);

  const [establishment, setEstablishment] = useState(null);
  const [showLimits, setShowLimits] = useState(false);

  const { register, handleSubmit, control, reset } = useForm({
    reValidateMode: "onBlur",
    defaultValues: {
      cost_per_liter: 2.5,
      cost_per_kwh: 0.2526,
    },
  });

  const validationLabels = {
    name: {
      required: t("common.required", { name: t("common.name") }),
    },
    brand: {
      required: t("common.required", { name: t("common.brand") }),
    },
    stars: {
      required: t("common.required", { name: t("common.stars") }),
      min: t("common.min") + " 0",
      max: t("common.max") + " 5",
    },
    yellow_water_limit_per_person: {
      number: t("common.number_only"),
    },
    yellow_elec_limit_per_person: {
      number: t("common.number_only"),
    },
    red_water_limit_per_person: {
      number: t("common.number_only"),
    },
    red_elec_limit_per_person: {
      number: t("common.number_only"),
    },
    screen_brightness: {
      number: t("common.number_only"),
      min: t("common.min") + " 0",
      max: t("common.max") + " 100",
    },
    uplink_rate_seconds: {
      number: t("common.number_only"),
    },
    screen_on_start: {
      number: t("common.number_only"),
      min: t("common.min") + " 0",
      max: t("common.max") + " 23",
    },
    screen_on_end: {
      number: t("common.number_only"),
      min: t("common.min") + " 0",
      max: t("common.max") + " 23",
    },
    cost_per_liter: {
      number: t("common.number_only"),
    },
    cost_per_kwh: {
      number: t("common.number_only"),
    },
  };

  const onSubmit = async (data) => {
    let {
      name,
      brand_id,
      yellow_water_limit_per_person,
      yellow_elec_limit_per_person,
      red_water_limit_per_person,
      red_elec_limit_per_person,
      screen_brightness,
      uplink_rate_seconds,
      screen_on_start,
      screen_on_end,
      stars,
      cost_per_liter,
      cost_per_kwh,
    } = data;

    let jsonData = {
      name: name,
      brand_id: brand_id,
      stars: stars,
      yellow_water_limit_per_person: yellow_water_limit_per_person,
      yellow_elec_limit_per_person: yellow_elec_limit_per_person,
      red_water_limit_per_person: red_water_limit_per_person,
      red_elec_limit_per_person: red_elec_limit_per_person,
      screen_brightness: screen_brightness,
      uplink_rate_seconds:
        userDB && userDB.admin
          ? uplink_rate_seconds
          : establishment.uplink_rate_seconds,
      screen_on_start: screen_on_start,
      screen_on_end: screen_on_end,
      cost_per_liter: cost_per_liter,
      cost_per_kwh: cost_per_kwh,
    };

    try {
      let response = null;

      if (establishment) {
        response = await putData(
          `/establishments/${establishment.id}`,
          jsonData
        );
      } else {
        response = await postData(`/establishments/`, jsonData);
      }

      if (response && response.status === 200) {
        updateSnackbarMessage(
          establishment === null
            ? t("common.added_success", { name: t("common.establishment") })
            : t("common.updated_success", { name: t("common.establishment") })
        );

        fetchEstablishments();
        navigate("/establishments/");
      } else {
        updateSnackbarMessage(
          establishment === null
            ? t("common.add_error", { name: t("common.establishment") })
            : t("common.update_error", { name: t("common.establishment") })
        );
      }
    } catch (error) {
      updateSnackbarMessage(
        establishment === null
          ? t("common.add_error", { name: t("common.establishment") }) + error
          : t("common.update_error", { name: t("common.establishment") }) +
              error
      );
    }
  };

  useEffect(() => {
    store.dispatch({
      type: "pageActions/update",
      payload: [],
    });

    store.dispatch({
      type: "pageTitle/update",
      payload: t("common.add_new", { name: t("common.establishment") }),
    });

    if (!params.id) {
      return;
    }

    fetchData(`/establishments/${params.id}`).then((data) => {
      setEstablishment(data);
    });
  }, [params, t]);

  useEffect(() => {
    if (!establishment) {
      return;
    }

    store.dispatch({
      type: "pageTitle/update",
      payload: t("common.update") + " " + t("common.establishment"),
    });

    reset({
      name: establishment.name,
      brand_id: establishment.brand_id,
      stars: establishment.stars,
      yellow_water_limit_per_person:
        establishment.yellow_water_limit_per_person,
      yellow_elec_limit_per_person: establishment.yellow_elec_limit_per_person,
      red_water_limit_per_person: establishment.red_water_limit_per_person,
      red_elec_limit_per_person: establishment.red_elec_limit_per_person,
      screen_brightness: establishment.screen_brightness,
      uplink_rate_seconds: establishment.uplink_rate_seconds,
      screen_on_start: establishment.screen_on_start,
      screen_on_end: establishment.screen_on_end,
      cost_per_liter: establishment.cost_per_liter,
      cost_per_kwh: establishment.cost_per_kwh,
    });
  }, [establishment, reset, t]);

  return (
    <Grid item xs={12}>
      <Grid container spacing={2} direction={"row"}>
        <Grid item xs={4}>
          <Controller
            name="name"
            control={control}
            rules={{
              required: true,
            }}
            render={({ field, fieldState: { error } }) => (
              <FormControl
                fullWidth
                variant="outlined"
                sx={{
                  mt: 1,
                }}
              >
                <TextField
                  id="name"
                  label={t("common.name")}
                  fullWidth
                  {...field}
                  error={error !== undefined}
                  helperText={error ? validationLabels.name[error.type] : ""}
                  variant="outlined"
                  sx={{
                    mt: 1,
                  }}
                  InputLabelProps={{ shrink: true }}
                  {...register("name")}
                />
              </FormControl>
            )}
          />
        </Grid>
        <Grid item xs={4}>
          <Controller
            name="stars"
            control={control}
            rules={{
              required: true,
              min: 0,
              max: 5,
            }}
            render={({ field, fieldState: { error } }) => (
              <FormControl
                fullWidth
                variant="outlined"
                sx={{
                  mt: 1,
                }}
              >
                <TextField
                  id="stars"
                  label={t("common.stars")}
                  fullWidth
                  {...field}
                  type="number"
                  error={error !== undefined}
                  helperText={error ? validationLabels.stars[error.type] : ""}
                  variant="outlined"
                  sx={{
                    mt: 1,
                  }}
                  InputLabelProps={{ shrink: true }}
                  {...register("stars")}
                />
              </FormControl>
            )}
          />
        </Grid>

        <Grid item xs={4}>
          <Controller
            name="brand_id"
            control={control}
            defaultValue={1}
            rules={{
              required: true,
            }}
            render={({ field, fieldState: { error } }) => (
              <FormControl
                fullWidth
                variant="outlined"
                sx={{
                  mt: 1,
                }}
              >
                <TextField
                  id="brand_id"
                  label={t("common.brand")}
                  fullWidth
                  select
                  {...field}
                  error={error !== undefined}
                  helperText={error ? validationLabels.brand[error.type] : ""}
                  variant="outlined"
                  sx={{
                    mt: 1,
                  }}
                  InputLabelProps={{ shrink: true }}
                  {...register("brand_id")}
                >
                  {brands.map((brand) => (
                    <MenuItem key={brand.id} value={brand.id}>
                      {brand.name}
                    </MenuItem>
                  ))}
                </TextField>
              </FormControl>
            )}
          />
        </Grid>

        <Grid item xs={6}>
          <Controller
            name="cost_per_liter"
            control={control}
            rules={{
              number: true,
            }}
            render={({ field, fieldState: { error } }) => (
              <FormControl
                fullWidth
                variant="outlined"
                sx={{
                  mt: 1,
                }}
              >
                <TextField
                  id="cost_per_liter"
                  label={t("common.cost_per_liter")}
                  fullWidth
                  type={"number"}
                  {...field}
                  error={error !== undefined}
                  helperText={
                    error ? validationLabels.cost_per_liter[error.type] : ""
                  }
                  variant="outlined"
                  sx={{
                    mt: 1,
                  }}
                  {...register("cost_per_liter")}
                  InputLabelProps={{ shrink: true }}
                  slotProps={{
                    input: {
                      endAdornment: (
                        <InputAdornment position="end">€</InputAdornment>
                      ),
                    },
                  }}
                />
              </FormControl>
            )}
          />
        </Grid>

        <Grid item xs={6}>
          <Controller
            name="cost_per_kwh"
            control={control}
            rules={{
              number: true,
            }}
            render={({ field, fieldState: { error } }) => (
              <FormControl
                fullWidth
                variant="outlined"
                sx={{
                  mt: 1,
                }}
              >
                <TextField
                  id="cost_per_kwh"
                  label={t("common.cost_per_kwh")}
                  fullWidth
                  type={"number"}
                  {...field}
                  error={error !== undefined}
                  helperText={
                    error ? validationLabels.cost_per_kwh[error.type] : ""
                  }
                  variant="outlined"
                  sx={{
                    mt: 1,
                  }}
                  {...register("cost_per_kwh")}
                  InputLabelProps={{ shrink: true }}
                  slotProps={{
                    input: {
                      endAdornment: (
                        <InputAdornment position="end">€</InputAdornment>
                      ),
                    },
                  }}
                />
              </FormControl>
            )}
          />
        </Grid>

        {showLimits && (
          <>
            <Grid item xs={3}>
              <Controller
                name="yellow_water_limit_per_person"
                control={control}
                rules={{
                  number: true,
                }}
                render={({ field, fieldState: { error } }) => (
                  <FormControl
                    fullWidth
                    variant="outlined"
                    sx={{
                      mt: 1,
                    }}
                  >
                    <TextField
                      id="yellow_water_limit_per_person"
                      label={t("common.yellow_water_limit_per_person")}
                      type={"number"}
                      slotProps={{
                        input: {
                          endAdornment: (
                            <InputAdornment position="end">
                              Liters
                            </InputAdornment>
                          ),
                        },
                      }}
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                      {...field}
                      error={error !== undefined}
                      helperText={
                        error
                          ? validationLabels.yellow_water_limit_per_person[
                              error.type
                            ]
                          : ""
                      }
                      variant="outlined"
                      sx={{
                        mt: 1,
                      }}
                      {...register("yellow_water_limit_per_person")}
                    />
                  </FormControl>
                )}
              />
            </Grid>
            <Grid item xs={3}>
              <Controller
                name="yellow_elec_limit_per_person"
                control={control}
                rules={{
                  number: true,
                }}
                render={({ field, fieldState: { error } }) => (
                  <FormControl
                    fullWidth
                    variant="outlined"
                    sx={{
                      mt: 1,
                    }}
                  >
                    <TextField
                      id="yellow_elec_limit_per_person"
                      label={t("common.yellow_elec_limit_per_person")}
                      fullWidth
                      slotProps={{
                        input: {
                          endAdornment: (
                            <InputAdornment position="end">kWh</InputAdornment>
                          ),
                        },
                      }}
                      InputLabelProps={{ shrink: true }}
                      {...field}
                      type={"number"}
                      error={error !== undefined}
                      helperText={
                        error
                          ? validationLabels.yellow_elec_limit_per_person[
                              error.type
                            ]
                          : ""
                      }
                      variant="outlined"
                      sx={{
                        mt: 1,
                      }}
                      {...register("yellow_elec_limit_per_person")}
                    />
                  </FormControl>
                )}
              />
            </Grid>
            <Grid item xs={3}>
              <Controller
                name="red_water_limit_per_person"
                control={control}
                rules={{
                  number: true,
                }}
                render={({ field, fieldState: { error } }) => (
                  <FormControl
                    fullWidth
                    variant="outlined"
                    sx={{
                      mt: 1,
                    }}
                  >
                    <TextField
                      id="red_water_limit_per_person"
                      label={t("common.red_water_limit_per_person")}
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      slotProps={{
                        input: {
                          endAdornment: (
                            <InputAdornment position="end">
                              Liters
                            </InputAdornment>
                          ),
                        },
                      }}
                      type={"number"}
                      {...field}
                      error={error !== undefined}
                      helperText={
                        error
                          ? validationLabels.red_water_limit_per_person[
                              error.type
                            ]
                          : ""
                      }
                      variant="outlined"
                      sx={{
                        mt: 1,
                      }}
                      {...register("red_water_limit_per_person")}
                    />
                  </FormControl>
                )}
              />
            </Grid>
            <Grid item xs={3}>
              <Controller
                name="red_elec_limit_per_person"
                control={control}
                //rules, number only
                rules={{
                  number: true,
                }}
                render={({ field, fieldState: { error } }) => (
                  <FormControl
                    fullWidth
                    variant="outlined"
                    sx={{
                      mt: 1,
                    }}
                  >
                    <TextField
                      id="red_elec_limit_per_person"
                      label={t("common.red_elec_limit_per_person")}
                      fullWidth
                      type="number"
                      InputLabelProps={{ shrink: true }}
                      slotProps={{
                        input: {
                          endAdornment: (
                            <InputAdornment position="end">kWh</InputAdornment>
                          ),
                        },
                      }}
                      {...field}
                      error={error !== undefined}
                      helperText={
                        error
                          ? validationLabels.red_elec_limit_per_person[
                              error.type
                            ]
                          : ""
                      }
                      variant="outlined"
                      sx={{
                        mt: 1,
                      }}
                      {...register("red_elec_limit_per_person")}
                    />
                  </FormControl>
                )}
              />
            </Grid>
            <Grid item xs={3}>
              <Controller
                name="screen_brightness"
                control={control}
                //rules number only, 0 - 100
                rules={{
                  number: true,
                  min: 0,
                  max: 100,
                }}
                render={({ field, fieldState: { error } }) => (
                  <FormControl
                    fullWidth
                    variant="outlined"
                    sx={{
                      mt: 1,
                    }}
                  >
                    <TextField
                      id="screen_brightness"
                      label={t("common.screen_brightness")}
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      slotProps={{
                        input: {
                          endAdornment: (
                            <InputAdornment position="end">%</InputAdornment>
                          ),
                        },
                      }}
                      {...field}
                      type={"number"}
                      error={error !== undefined}
                      helperText={
                        error
                          ? validationLabels.screen_brightness[error.type]
                          : ""
                      }
                      variant="outlined"
                      sx={{
                        mt: 1,
                      }}
                      {...register("screen_brightness")}
                    />
                  </FormControl>
                )}
              />
            </Grid>
            {userDB && userDB.admin ? (
              <Grid item xs={3}>
                <Controller
                  name="uplink_rate_seconds"
                  control={control}
                  rules={{
                    number: true,
                  }}
                  render={({ field, fieldState: { error } }) => (
                    <FormControl
                      fullWidth
                      variant="outlined"
                      sx={{
                        mt: 1,
                      }}
                    >
                      <TextField
                        id="uplink_rate_seconds"
                        label={t("common.uplink_rate_seconds")}
                        fullWidth
                        type={"number"}
                        InputLabelProps={{ shrink: true }}
                        slotProps={{
                          input: {
                            endAdornment: (
                              <InputAdornment position="end">
                                {t("common.seconds")}
                              </InputAdornment>
                            ),
                          },
                        }}
                        {...field}
                        error={error !== undefined}
                        helperText={
                          error
                            ? validationLabels.uplink_rate_seconds[error.type]
                            : ""
                        }
                        variant="outlined"
                        sx={{
                          mt: 1,
                        }}
                        {...register("uplink_rate_seconds")}
                      />
                    </FormControl>
                  )}
                />
              </Grid>
            ) : null}
            <Grid item xs={3}>
              <Controller
                name="screen_on_start"
                control={control}
                rules={{
                  number: true,
                  min: 0,
                  max: 23,
                }}
                render={({ field, fieldState: { error } }) => (
                  <FormControl
                    fullWidth
                    variant="outlined"
                    sx={{
                      mt: 1,
                    }}
                  >
                    <TextField
                      id="screen_on_start"
                      label={t("common.screen_on_start")}
                      type={"number"}
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      {...field}
                      error={error !== undefined}
                      helperText={
                        error
                          ? validationLabels.screen_on_start[error.type]
                          : ""
                      }
                      variant="outlined"
                      sx={{
                        mt: 1,
                      }}
                      {...register("screen_on_start")}
                    />
                  </FormControl>
                )}
              />
            </Grid>
            <Grid item xs={3}>
              <Controller
                name="screen_on_end"
                control={control}
                rules={{
                  number: true,
                  min: 0,
                  max: 23,
                }}
                render={({ field, fieldState: { error } }) => (
                  <FormControl
                    fullWidth
                    variant="outlined"
                    sx={{
                      mt: 1,
                    }}
                  >
                    <TextField
                      id="screen_on_end"
                      label={t("common.screen_on_end")}
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      type={"number"}
                      {...field}
                      error={error !== undefined}
                      helperText={
                        error ? validationLabels.screen_on_end[error.type] : ""
                      }
                      variant="outlined"
                      sx={{
                        mt: 1,
                      }}
                      {...register("screen_on_end")}
                    />
                  </FormControl>
                )}
              />
            </Grid>
          </>
        )}

        <Grid item xs={12}>
          <Stack
            spacing={2}
            direction="row"
            sx={{
              mt: 2,
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button
              variant="outlined"
              onClick={() => setShowLimits(!showLimits)}
            >
              {showLimits ? t("common.hide_limits") : t("common.show_limits")}
            </Button>
            <Button
              variant="contained"
              color="success"
              onClick={handleSubmit(onSubmit)}
            >
              {establishment === null ? t("common.add") : t("common.update")}
            </Button>
          </Stack>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default withAuthenticationRequired(EstablishmentUpdate);
