import { useTheme } from "@emotion/react";
import { Card, CardContent, CardHeader } from "@mui/material";
import {
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  Rectangle,
  Bar,
  BarChart,
} from "recharts";
import moment from "moment";
import { useTranslation } from "react-i18next";

export default function DeviceGraph(props) {
  const { title, value, data, aspect, groupedBy, unit } = props;

  let valueName = value;

  const { t } = useTranslation("common");

  const theme = useTheme();

  return (
    <Card>
      <CardHeader title={title} />
      <CardContent>
        <ResponsiveContainer aspect={aspect} width="100%">
          <BarChart
            data={data}
            margin={{ top: 0, right: 30, left: 0, bottom: 0 }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              dataKey="time_created"
              type={"category"} //format date to be more readable
              tickFormatter={(time) => {
                if (groupedBy === "daily") {
                  //remove time from date
                  return moment(time).format("DD/MM/YYYY");
                } else if (groupedBy === "hourly") {
                  return moment(time).format("HH:mm");
                } else {
                  return moment(time).format("DD/MM/YYYY HH:mm");
                }
              }}
            />
            <YAxis />
            <Tooltip
              cursor={false}
              contentStyle={{
                backgroundColor: theme.palette.background.default,
              }}
              formatter={(value, name, props) => {
                //name will be total_kwh or total_liters, format to kwh or liters
                if (name === "total_kwh") {
                  valueName = t("common.total_kwh");
                } else if (name === "total_liters") {
                  valueName = t("common.total_liters");
                }
                value = Math.round(value * 100) / 100;
                return [value + unit, valueName];
              }}
              tickFormatter={(time) => {
                if (groupedBy === "daily") {
                  return new Date(time).format("DD/MM/YYYY");
                } else if (groupedBy === "hourly") {
                  return new Date(time).format("HH:mm");
                } else {
                  return new Date(time).format("DD/MM/YYYY HH:mm");
                }
              }}
            />
            <Bar
              dataKey={value}
              fill="#6bc769"
              isAnimationActive={false}
              activeBar={<Rectangle fill="#bdbdbd" />}
            />
          </BarChart>
        </ResponsiveContainer>
      </CardContent>
    </Card>
  );
}
