import { withAuthenticationRequired } from "@auth0/auth0-react";
import { store } from "../store";
import { useTranslation } from "react-i18next";
import { Grid } from "@mui/material";
import EstablishmentsTable from "../components/tables/Establishments";
import { useEffect } from "react";
import { useSelector } from "react-redux";

function Establishments(props) {
  const { t } = useTranslation("common");

  const userDB = useSelector((state) => state.userDB);

  useEffect(() => {
    store.dispatch({
      type: "pageTitle/update",
      payload: t("common.establishments"),
    });

    let pageActions = [];

    if (userDB.admin) {
      pageActions = [
        {
          name: t("common.add_new", { name: "" }),
          icon: "Add",
          url: "/add/establishment",
        },
      ];
    }

    store.dispatch({ type: "pageActions/update", payload: pageActions });
  }, [t, userDB.admin]);

  return (
    <Grid item xs={12}>
      <EstablishmentsTable />
    </Grid>
  );
}

export default withAuthenticationRequired(Establishments);
