import { useTheme } from "@emotion/react";
import { Icon, Card, Grid, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

export default function DashboardCard(props) {
  const { residence } = props;

  const theme = useTheme();

  const screens = useSelector((state) => state.screens);

  const establishments = useSelector((state) => state.establishments);

  const establishment = establishments.find(
    (establishment) =>
      parseInt(establishment.id) === parseInt(residence.establishment_id)
  );
  const screen = screens.find(
    (screen) => parseInt(screen.pack_id) === parseInt(residence.pack_id)
  );

  const navigate = useNavigate();

  let water_cost = 0;
  let elec_cost = 0;
  let water_total = 0;
  let elec_total = 0;

  if (screen) {
    water_total = screen.water_liter;
    elec_total = screen.electricity_kwh;
    water_cost = (screen.water_liter * establishment.cost_per_liter) / 1000;
    elec_cost = screen.electricity_kwh * establishment.cost_per_kwh;
  }

  if (!residence) return null;

  return (
    <Grid item xs={12} md={6} lg={4} key={residence.id}>
      <Card
        elevation={3}
        sx={{
          p: 2,

          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          borderRadius: 2,
          cursor: "pointer",
          transition: "transform 0.3s ease, box-shadow 0.3s ease",
          "&:hover": {
            transform: "translateY(-2px)",
            boxShadow: 6,
          },
        }}
        onClick={() => {
          //navigate to the residence usage page
          navigate("/view/" + residence.id);
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={5}>
            <Typography
              variant="subtitle1"
              component="p"
              sx={{
                fontSize: "1em",
                fontWeight: "bold",
                lineHeight: "1.4em",
              }}
            >
              <b>{residence.name}</b>
            </Typography>
            <Typography
              variant="caption"
              component="p"
              sx={{
                fontSize: "0.8em",
                lineHeight: "0.8em",
                color: "text.secondary",
              }}
            >
              Occupancy: <b>{residence.current_occupation}</b>/
              <b>{residence.capacity}</b>
            </Typography>
          </Grid>

          <Grid item xs={2} sx={{ display: "flex", alignItems: "center" }}>
            <Icon
              sx={{
                backgroundColor:
                  screen &&
                  screen.smiley_color &&
                  screen.smiley_color === "green"
                    ? theme.palette.green.main
                    : screen &&
                      screen.smiley_color &&
                      screen.smiley_color === "yellow"
                    ? theme.palette.yellow.main
                    : theme.palette.red.main,
                color: "black",
                fontSize: "1em",
                borderRadius: "50%",
                p: 1,
                boxShadow: 2,
              }}
            >
              {screen && screen.smiley_color && screen.smiley_color === "green"
                ? "sentiment_satisfied_alt"
                : screen &&
                  screen.smiley_color &&
                  screen.smiley_color === "yellow"
                ? "sentiment_dissatisfied"
                : "sentiment_very_dissatisfied"}
            </Icon>
          </Grid>
          <Grid item xs={2} sx={{ display: "flex", alignItems: "center" }}>
            <Icon sx={{ color: "info.main", mr: 1, fontSize: "2em" }}>
              water_drop
            </Icon>
            <Typography
              variant="caption"
              component="p"
              sx={{
                fontSize: "0.8em",
                lineHeight: "1em",
              }}
            >
              <b>{water_total}</b>L
              <br />
              {water_cost.toFixed(2)}&euro;
            </Typography>
          </Grid>
          <Grid item xs={2} sx={{ display: "flex", alignItems: "center" }}>
            <Icon sx={{ color: "warning.main", mr: 1, fontSize: "2em" }}>
              bolt
            </Icon>
            <Typography
              variant="caption"
              component="p"
              sx={{
                fontSize: "0.8em",
                lineHeight: "1em",
              }}
            >
              <b>{elec_total}</b>kWh
              <br />
              {elec_cost.toFixed(2)}&euro;
            </Typography>
          </Grid>
        </Grid>
      </Card>
    </Grid>
  );
}
