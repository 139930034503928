import { useSelector } from "react-redux";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import DeviceCard from "./DeviceCard";

export default function DeviceCards() {
  const devices = useSelector((state) => state.devices);
  const { t } = useTranslation("common");

  return (
    <>
      {devices.length > 0 ? (
        devices.map((device) => (
          <DeviceCard key={device.dev_eui} device={device} />
        ))
      ) : (
        <Typography variant="h6">{t("common.no_devices")}</Typography>
      )}
    </>
  );
}
