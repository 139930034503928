import { useSelector } from "react-redux";
import DataTableBase from "./DataTableBase";
import { useTranslation } from "react-i18next";
import React from "react";
import { useNavigate } from "react-router-dom";
import { store } from "../../store";

export default function ResidencesTable() {
  const residences = useSelector((state) => state.residences);
  const establishments = useSelector((state) => state.establishments);
  const userDB = useSelector((state) => state.userDB);

  const navigate = useNavigate();

  const { t } = useTranslation("common");

  const columns = [
    {
      name: "id",
      label: "ID",
      options: {
        display: false,
        filter: false,
        sort: true,
      },
    },
    {
      name: "name",
      label: t("common.name"),
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "establishment_id",
      label: t("common.establishment"),
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => {
          let establishment = establishments.find(
            (establishment) => parseInt(establishment.id) === parseInt(value)
          );

          return establishment ? establishment.name : "";
        },
        sortCompare: (order) => {
          return (obj1, obj2) => {
            let val1 = obj1.data;
            let val2 = obj2.data;

            let establishmentA = establishments.find(
              (establishment) => parseInt(establishment.id) === parseInt(val1)
            );
            let establishmentB = establishments.find(
              (establishment) => parseInt(establishment.id) === parseInt(val2)
            );

            return (
              establishmentA.name.localeCompare(establishmentB.name) *
              (order === "asc" ? 1 : -1)
            );
          };
        },
      },
    },
    {
      name: "pack_id",
      label: t("common.pack"),
      options: {
        filter: true,
        sort: true,
      },
      sortCompare: (order) => {
        return (obj1, obj2) => {
          let val1 = obj1.data;
          let val2 = obj2.data;

          const isNumeric = (value) => {
            return /^-?\d+$/.test(value);
          };

          if (isNumeric(val1) && isNumeric(val2)) {
            return (
              (parseInt(val1) - parseInt(val2)) * (order === "asc" ? 1 : -1)
            );
          } else if (isNumeric(val1)) {
            return -1;
          } else if (isNumeric(val2)) {
            return 1;
          } else {
            return val1.localeCompare(val2) * (order === "asc" ? 1 : -1);
          }
        };
      },
    },
    {
      name: "current_occupation",
      label: t("common.current_occupation"),
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "capacity",
      label: t("common.capacity"),
      options: {
        filter: true,
        sort: true,
      },
    },
  ];

  const [selectedResidences, setSelectedresidences] = React.useState([]);
  const [selectedRows, setSelectedRows] = React.useState([]);

  const tableActions = [
    {
      name: t("common.delete_selected", {
        name: t("common.residences"),
        count: selectedResidences.length,
      }),
      icon: "Delete",
      action: () => {
        store.dispatch({
          type: "deleteDialog/update",
          payload: {
            open: true,
            type: "residences",
            records: selectedResidences,
          },
        });
      },
    },
  ];

  return (
    <DataTableBase
      columns={columns}
      data={residences}
      title={t("common.residences")}
      id="residence-list"
      name="residence-list"
      pageSize={10}
      selectedItems={selectedResidences}
      setSelectedItems={setSelectedresidences}
      selectedRows={selectedRows}
      setSelectedRows={setSelectedRows}
      selectableRows={userDB ? (userDB.admin ? "multiple" : "none") : "none"}
      actions={tableActions}
      sortOrder="dev_eui"
      onRowClick={(rowData) => {
        navigate(`/residences/${rowData[0]}`);
      }}
    />
  );
}
